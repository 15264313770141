import { Button, Form, message, Select, Table } from "antd";
import { AddConnectionWrapper, CustomInput, CustomInputPw } from "../style";
import {
  CheckmarkCircleFill,
  MongoLogo,
  MySQLLogo,
  OneDriveLogo,
  PdfIcon,
  PostgreLogo,
} from "assets/icons";
import { useEffect, useState } from "react";
import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  createDataSource,
  deleteProjectSetting,
  getDataSourceInfo,
  testConnection,
} from "services/project";
import { useAuthStore } from "stores/useAuthStore";
import dayjs from "dayjs";
import { createConnection } from "services/dataQuality";

type FieldType = {
  db_type?: string;
  name?: string;
  host?: string;
  port?: string;
  username?: string;
  password?: string;
  database?: string;
  url?: string;
};

const { Option } = Select;

const pdfFiles = [
  {
    key: "1",
    name: "AIC_FPT_CV_Vu Thi Han.pdf",
    size: "119KB",
  },
  {
    key: "2",
    name: "BaoDHG2_AI Residency_CV - Bao Dinh.pdf",
    size: "101KB",
  },
  {
    key: "3",
    name: "CuongDC10_AI Residency_CV - Captain Cuong",
    size: "134KB",
  },
  {
    key: "4",
    name: "CuongLC4_AI Residency_CV - Lê Chí Cường",
    size: "117KB",
  },
  {
    key: "5",
    name: "CuongNL8_AI Residency_CV - Cuong Nguyen Lan",
    size: "694KB",
  },
  {
    key: "6",
    name: "CV_Anh Mai Viet_AnhMV4.pdf",
    size: "238KB",
  },
  {
    key: "7",
    name: "CV_HUYNH-TRUNG-NGHIA.pdf",
    size: "193KB",
  },
  {
    key: "8",
    name: "CV_Le Hanh Quyen_DA.pdf",
    size: "136KB",
  },
  {
    key: "9",
    name: "CV_NguyenGiaQuang_FPT_2024_update.pdf",
    size: "145KB",
  },
  {
    key: "10",
    name: "CV_Nguyen-Xuan-Nhat.pdf",
    size: "177KB",
  },
  {
    key: "11",
    name: "CV_Pham Van Manh.docx.pdf",
    size: "84.7KB",
  },
  {
    key: "12",
    name: "CV_Pham-Xuan-Tinh.pdf",
    size: "220KB",
  },
  {
    key: "13",
    name: "CV_Thai Tran Quang_ThaiTQ23_20240905.pdf",
    size: "209KB",
  },
  {
    key: "14",
    name: "CV_TRAN-HOANG-NGUYEN.pdf",
    size: "182KB",
  },
  {
    key: "15",
    name: "CV_TranTrungHieu_fsoft.pdf",
    size: "119KB",
  },
  {
    key: "16",
    name: "CV_Truong-Hoang-Van_TruongHV1_20240831.pdf",
    size: "281KB",
  },
  {
    key: "17",
    name: "DatNC13_AI Residency_CV - Dat Nguyen Cong.pdf",
    size: "127KB",
  },
  {
    key: "18",
    name: "DuyNVT3_AI Residency_CV - Nguyễn Việt Thanh Duy.pdf",
    size: "255KB",
  },
  {
    key: "19",
    name: "FPT DaoTC2_AI Residency_CV.pdf",
    size: "128KB",
  },
  {
    key: "20",
    name: "FPT HoangVV18_AI Residency_CV - Hoàng Vũ Việt.pdf",
    size: "152KB",
  },
  {
    key: "21",
    name: "FPT HuynhND11_AI Residency_CV - Đăng Huỳnh Nguyễn.pdf",
    size: "108KB",
  },
  {
    key: "22",
    name: "FPT NinhNQ3_AI Residency_CV - Ninh Nguyễn Quang.pdf",
    size: "113KB",
  },
  {
    key: "23",
    name: "FPT TrucCTT_AI Residency_CV - Thanh Truc Chau.pdf",
    size: "61.6KB",
  },
  {
    key: "24",
    name: "FPT_CV_AnDN2 1.pdf",
    size: "134KB",
  },
  {
    key: "25",
    name: "FPT_CV_AnhDTV7.pdf",
    size: "122KB",
  },
  {
    key: "26",
    name: "FPT_CV_Cao-Minh-Phat.pdf",
    size: "158KB",
  },
  {
    key: "27",
    name: "FPT_CV_Chau-Minh-Khang.pdf",
    size: "212KB",
  },
  {
    key: "28",
    name: "FPT_CV_Chuong-Hoa-Loc.pdf",
    size: "164KB",
  },
  {
    key: "29",
    name: "FPT_CV_Cu-Phuc-Khang",
    size: "216KB",
  },
  {
    key: "30",
    name: "FPT_CV_Dang-Quang-Vinh.pdf",
    size: "51.6KB",
  },
  {
    key: "31",
    name: "FPT_CV_Dang-Trong-Tuyen.pdf",
    size: "209KB",
  },
  {
    key: "32",
    name: "FPT_CV_Dang-Viet-Quan.pdf",
    size: "161KB",
  },
  {
    key: "33",
    name: "FPT_CV_Dat-Luong-Tat_DatLT94.pdf",
    size: "447KB",
  },
  {
    key: "34",
    name: "FPT_CV_Dinh-Thi-Kim-Mai.pdf",
    size: "126KB",
  },
  {
    key: "35",
    name: "FPT_CV_Doan-Trong-Hieu 1.pdf",
    size: "393KB",
  },
  {
    key: "36",
    name: "FPT_CV_Do-Minh-Quyet.pdf",
    size: "223KB",
  },
  {
    key: "37",
    name: "FPT_CV_Do-Nguyen-Hoang-Anh - Hoàng Anh Đỗ Nguyễn.pdf",
    size: "164KB",
  },
  {
    key: "38",
    name: "FPT_CV_Do-Quang-Vinh.pdf",
    size: "171KB",
  },
  {
    key: "39",
    name: "FPT_CV_Do-Thanh-Dat.pdf",
    size: "107KB",
  },
  {
    key: "40",
    name: "FPT_CV_Do-Van-Tuan.pdf",
    size: "103KB",
  },
  {
    key: "41",
    name: "FPT_CV_DuongLLD.pdf",
    size: "94.6KB",
  },
  {
    key: "42",
    name: "FPT_CV_DuyNK29.pdf",
    size: "316KB",
  },
  {
    key: "43",
    name: "FPT_CV_HAO-PHAN-PHU.pdf",
    size: "171KB",
  },
];

export function AddConnection() {
  const [form] = Form.useForm();
  const [isTesting, setIsTesting] = useState(false);
  const [isTestingSuccessfull, setIsTestingSuccessfull] = useState(0);
  const [isSaveSuccessfull, setIsSaveSuccessfull] = useState(false);
  const { userId, setUserId, setHasSetting } = useAuthStore();
  const [isOneDrive, setIsOneDrive] = useState(false);
  const [listPdf, setListPdf] = useState<any>([]);

  useEffect(() => {
    getDataSource();
  }, []);

  const getDataSource = async () => {
    try {
      if (!userId) return;
      const res = await getDataSourceInfo(userId);
      form.setFieldsValue({
        db_type: res.db_type,
        name: res.name,
        host: res.host,
        port: res.port,
        username: res.username,
        database: res.database,
      });
      console.log(res);
    } catch (error) {}
  };

  useEffect(() => {
    if (form.getFieldValue("db_type") === "OneDrive") {
      return;
    }
    const deleteProject = async () => {
      try {
        if (!userId) return;
        await deleteProjectSetting(userId as string);
        setHasSetting(false);
      } catch (error) {}
    };
    if (isSaveSuccessfull) {
      deleteProject();
    }
  }, [isSaveSuccessfull, userId]);

  const handleTestConnection = async (isTest: boolean) => {
    try {
      await form.validateFields();
      if (isTest) {
        if (form.getFieldValue("db_type") === "OneDrive") {
          setIsTesting(true);
          setTimeout(() => {
            setIsTesting(false);
            message.success("Connection success");
            setIsTestingSuccessfull(1);
          }, 500);
          return;
        }
        setIsTesting(true);
        const data = {
          ...form.getFieldsValue(),
        };
        await testConnection(data);
        setIsTestingSuccessfull(1);
        setIsTesting(false);
      } else {
        if (form.getFieldValue("db_type") === "OneDrive") {
          setIsSaveSuccessfull(true);
          setTimeout(() => {
            setIsSaveSuccessfull(false);
            message.success("Connection success");
            setListPdf(pdfFiles);
          }, 500);
          return;
        }
        setIsSaveSuccessfull(false);
        let id = userId;
        if (!id) {
          id = dayjs().unix().toString();
          setUserId(dayjs().unix().toString());
        }
        const data = {
          ...form.getFieldsValue(),
        };
        await testConnection(data);
        await createDataSource(data, id);
        await createConnection(
          {
            db_type: form.getFieldValue("db_type"),
            connection_name: id,
            server: form.getFieldValue("host"),
            port: form.getFieldValue("port") + "",
            database: form.getFieldValue("database"),
            username: form.getFieldValue("username"),
            password: form.getFieldValue("password"),
          },
          id
        );
        setIsSaveSuccessfull(true);
      }
      message.success("Connection success");
    } catch (error) {
      console.log(error);
      if (isTest) {
        setIsTesting(false);
        setIsTestingSuccessfull(2);
      } else {
        setIsSaveSuccessfull(false);
      }
      message.error("Connection failed");
    }
  };

  const handleDbTypeChange = (value: string) => {
    setListPdf([]);
    setIsOneDrive(value === "OneDrive");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: string) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <PdfIcon style={{ marginRight: "10px" }} />
          {value}
        </div>
      ),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
    },
  ];

  return (
    <AddConnectionWrapper>
      <div className="title">Add connection</div>
      <div className="sub-title">
        Choose data source then fill all required informations to to integrate
        data into project
      </div>
      {/* <div className="db-type">
        <PostgreLogo style={{ width: "32px", height: "32px" }} /> Postgre
      </div> */}
      <Form
        form={form}
        layout="vertical"
        style={{
          width: "100%",
          display: "flex",
          gap: "10px",
          flexDirection: "column",
        }}
      >
        <Form.Item<FieldType>
          label="Database type"
          name="db_type"
          rules={[{ required: true, message: "Please select database type" }]}
        >
          <Select placeholder="Select an option" onChange={handleDbTypeChange}>
            <Option value="PostgreSQL">
              <div style={{ display: "flex", alignItems: "center" }}>
                <PostgreLogo
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />{" "}
                Postgres
              </div>
            </Option>
            <Option value="Mongo">
              <div style={{ display: "flex", alignItems: "center" }}>
                <MongoLogo
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />{" "}
                Mongo
              </div>
            </Option>
            <Option value="MySQL">
              <div style={{ display: "flex", alignItems: "center" }}>
                <MySQLLogo
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />{" "}
                MySQL
              </div>
            </Option>
            <Option value="OneDrive">
              <div style={{ display: "flex", alignItems: "center" }}>
                <OneDriveLogo
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />{" "}
                OneDrive
              </div>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item<FieldType>
          label="Name"
          name="name"
          rules={[{ required: !isOneDrive, message: "Please input name!" }]}
          hidden={isOneDrive}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Server"
          name="host"
          rules={[{ required: !isOneDrive, message: "Please input host!" }]}
          hidden={isOneDrive}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Port"
          name="port"
          rules={[{ required: !isOneDrive, message: "Please input port!" }]}
          hidden={isOneDrive}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Database"
          name="database"
          rules={[{ required: !isOneDrive, message: "Please input database!" }]}
          hidden={isOneDrive}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Username"
          name="username"
          rules={[{ required: !isOneDrive, message: "Please input username!" }]}
          hidden={isOneDrive}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="Password"
          name="password"
          rules={[{ required: !isOneDrive, message: "Please input password!" }]}
          hidden={isOneDrive}
        >
          <CustomInputPw maxLength={150} />
        </Form.Item>
        <Form.Item<FieldType>
          label="OneDrive URL"
          name="url"
          rules={[{ required: isOneDrive, message: "Please input url!" }]}
          hidden={!isOneDrive}
        >
          <CustomInput maxLength={150} />
        </Form.Item>
        <div className="footer">
          <div
            className="text-connection"
            onClick={() => handleTestConnection(true)}
          >
            {isTesting && <LoadingOutlined />}
            {!isTesting && isTestingSuccessfull === 1 && (
              <CheckmarkCircleFill />
            )}
            {!isTesting && isTestingSuccessfull === 2 && (
              <CloseCircleOutlined style={{ color: "red" }} />
            )}{" "}
            <span>Test connection</span>
          </div>
          <Button
            onClick={() => handleTestConnection(false)}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
      {listPdf.length > 0 && (
        <div>
          <Table
            columns={columns}
            dataSource={listPdf}
            style={{ marginTop: "20px" }}
          />
        </div>
      )}
    </AddConnectionWrapper>
  );
}
