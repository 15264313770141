import { Button, Checkbox, message, Modal, Select, Tooltip } from "antd";
import { useDataSourceModalStore } from "stores/useDataSourceModalStore";
import { SelectTableModalWrap } from "./style";
import { useContext, useEffect, useState } from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import {
  getListConnections,
  getListTables,
  indexTable,
} from "services/dataQuality";
import { useAuthStore } from "stores/useAuthStore";
import { useSelectTableModalStore } from "stores/useSelectTableModalStore";
import { ITableIndexerParam } from "types/dataQuality";
import { useSelectedTableStore } from "stores/useSelectedTableStore";

export function SelectTableModal() {
  const { isOpen, onClose } = useSelectTableModalStore();
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [listTables, setListTables] = useState<string[]>([]);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const { userId } = useAuthStore();
  const { setConnectionName, setListSelectedTable } = useSelectedTableStore();
  const [listTableSelected, setListTableSelected] = useState<string[]>([]);
  const [listTableSuccess, setListTableSuccess] = useState<string[]>([]);
  const [selectedConnection, setListSelectedConnection] = useState<string>();
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const getConnections = async () => {
    try {
      if (!userId) {
        return;
      }
      setIsLoadingTable(true);
      const result = await getListConnections(userId as string);
      // console.log(
      //   result.map((item) => {
      //     return { value: item.connection_name, label: item.connection_name };
      //   })
      // );
      if(result && result?.length > 0) {
        selectDataBase(result?.[result.length - 1].connection_name)
      }
      setIsLoadingTable(false);
      // setOptions(
      //   result.map((item) => {
      //     return { value: item.connection_name, label: item.connection_name };
      //   })
      // );
      // console.log(listTableUpdate);
    } catch (error) {
      setIsLoadingTable(false);
      // setHasSetting(false);
    }
  };

  useEffect(() => {
    getConnections();
  }, []);

  const selectDataBase = async (value: string) => {
    try {
      setListSelectedConnection(value);
      setIsLoadingTable(true);
      const result = await getListTables(userId as string, value);
      console.log("getListTables", result);
      setListTables(result.map((item) => item));
      setIsLoadingTable(false);
    } catch (error) {
      setIsLoadingTable(false);
    }
  };

  const onChange = (item: string) => {
    console.log(item);
    const temp = [...listTableSelected];
    const index = temp.findIndex((tempItem) => tempItem === item);
    if (index >= 0) {
      temp.splice(index, 1);
    } else {
      temp.push(item);
    }
    console.log("temp", temp);
    setListTableSelected(temp);
  };

  const submitTable = async (list: string[]) => {
    if (!userId || !selectedConnection) {
      return;
    }
    try {
      setConnectionName(selectedConnection);
      setIsSubmiting(true);
      const listPromise =
        list &&
        list?.length &&
        list.map(async (item) => {
          const data: ITableIndexerParam = {
            username: userId,
            db_table_name: item,
            connection_id: selectedConnection,
          };
          await indexTable(data);
        });
      const res = listPromise && (await Promise.allSettled(listPromise));
      if (res === 0) return;
      const listError = [];
      const listSuccess = [...listTableSuccess];
      for (let i = 0; i < res.length; i++) {
        if (res[i].status === "rejected") {
          listError.push(list[i]);
        } else if (res[i].status === "fulfilled") {
          listSuccess.push(list[i]);
        }
      }
      if (listError.length > 0) {        
        message.error(`File can not index: ${listError.join(', ')}`);
      }
      
      setListSelectedTable(listSuccess)
      setIsSubmiting(false);
      setListTableSuccess(listSuccess);
      onClose();
    } catch (error) {
      message.error(`Index file errors!`);
    }
  };

  return (
    <>
      {isOpen && (
        <Modal onCancel={onClose} open={isOpen} width={600} footer={null}>
          <SelectTableModalWrap>
            <div className="title">Hi!</div>
            <div className="sub-title">Please choose table</div>
            <div className="select-data-area">
              <div>
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                    gap: "10px",
                  }}
                >
                  <p className="label">Data Source:</p>
                  <Select
                    size="large"
                    placeholder="Select data source"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ maxWidth: "300px" }}
                    options={options}
                    onChange={selectDataBase}
                  />
                </div> */}
                <div className="select-table">
                  {isLoadingTable && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <LoadingOutlined />
                    </div>
                  )}
                  {listTables.map((item, index) => {
                    return (
                      <div
                        className="table-item"
                        key={(item || "") + index}
                        onClick={() => onChange(item || "")}
                      >
                        <Tooltip
                          title={item}
                          mouseEnterDelay={0.5}
                          mouseLeaveDelay={0.2}
                        >
                          <span className="table-name">{item}</span>
                        </Tooltip>
                        <Checkbox
                          defaultChecked={listTableSelected.some(
                            (selectedTable) => selectedTable === item
                          )}
                          checked={listTableSelected.some(
                            (selectedTable) => selectedTable === item
                          )}
                          onChange={() => onChange(item || "")}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() =>
                  submitTable(listTableSelected)
                }
                disabled={isSubmiting || listTableSelected.length === 0}
                type="primary"
              >
                Submit{" "}
                {isSubmiting && <LoadingOutlined />}
              </Button>
            </div>
          </SelectTableModalWrap>
        </Modal>
      )}
    </>
  );
}
