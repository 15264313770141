import { createContext, useEffect, useState } from "react";
import { Chat2ChartWrapper } from "./style";
import { ChatbotWindow } from "./components/ChatbotWindow";
import { DetailData } from "./components/DetailData";
import { DefaultLayout } from "layouts/DefaultLayout";
import { IDataQualityReport } from "types/dataQuality";
import { getDataSourceInfo, getDbSchema } from "services/project";
import { useAuthStore } from "stores/useAuthStore";
import { Select } from "antd";
import { IDbInfor } from "types/project";
import { getListConnections, getListTables } from "services/dataQuality";

export interface IDataQualityReportNewChat {
  data_quality_report_before_update?: IDataQualityReport[];
  data_quality_report_after_update?: IDataQualityReport[];
}

interface Chat2ChartContextValue {
  table?: string;
  dbInfor?: string;
  newContent?: IDataQualityReportNewChat[];
  updateNewContent: (newContent: IDataQualityReportNewChat[]) => void;
}

export const Chat2ChartContext = createContext<Chat2ChartContextValue>({
  newContent: undefined,
  table: undefined,
  dbInfor: '',
  updateNewContent: () => {},
});

export function DataQuality() {
  const [newContent, setNewContent] = useState<IDataQualityReportNewChat[]>();
  const [table, setTable] = useState<string>();
  const [dbInfor, setDbInfor] = useState<string>('');
  const { userId } = useAuthStore();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  const getDataSource = async () => {
    try {
      if (!userId) {
        return;
      }
      const db = await getListConnections(userId as string);
      if (db && db.length > 0) {
        setDbInfor(db[db.length - 1].connection_name)
        const result = await getListTables(
          userId as string,
          db[db.length - 1].connection_name
        );
        setOptions(
          result.map((item) => {
            return {
              value: item || "",
              label: item || "",
            };
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDataSource();
  }, []);

  // useEffect(() => {
  //   if(!hasSetting) {
  //     onOpen()
  //   }
  // }, [hasSetting])

  const selectTable = async (value: string) => {
    setTable(value);
  };

  return (
    <DefaultLayout>
      <Chat2ChartContext.Provider
        value={{
          dbInfor,
          table,
          newContent,
          updateNewContent: (newContent) => setNewContent(newContent),
        }}
      >
        <Chat2ChartWrapper>
          <div className="title">
            Data Quality{" "}
            <Select
              size="middle"
              placeholder="Select table"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={options}
              onChange={selectTable}
            />
          </div>
          <div className="body">
            <DetailData />
            <ChatbotWindow />
          </div>
        </Chat2ChartWrapper>
      </Chat2ChartContext.Provider>
    </DefaultLayout>
  );
}
