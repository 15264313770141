import styled from "styled-components";

export const SelectTableModalWrap = styled.div`
  width: 100%;
  margin: auto;
  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }
  .sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 30px;
  }
  .label {
    font-weight: 700;
    max-width: 210px;
  }
  .table-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .table-name,
  .label {
    padding-right: 10px;
    display: block;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .select-table {
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    padding: 0 20px;
    overflow: auto;
    height: 400px;
    .table-item {
      cursor: pointer;
      &.active {
        background: linear-gradient(90deg, #e9faf6 53.02%, #e4fffb 100%);
      }
    }
    & .table-item {
      padding: 20px 10px;
    }
    & .table-item:not(:last-child) {
      border-bottom: 1px solid #bfbfbf;
    }
  }
  .select-properties {
    border: 1px solid #bfbfbf;
    border-radius: 8px;
    padding: 20px;
    overflow: auto;
    height: 455px;
  }
  .property-item {
    padding: 10px 20px;
  }
  .property-header {
    padding: 20px;
  }
  .footer {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
`;
