import {
  IChatBotBody,
  IChatbotFeedback,
  IChatbotSurveyAdd,
  IChatData,
  IChatInfor,
  IGetChatbotSurveyParams,
  IReactionMsg,
  IReadMsgRequest,
} from "types/chatbot";
import { axiosNoAuthInstance } from "utils/axios";

export async function getChatbotMsg(
  requestType: string
): Promise<IChatInfor[]> {
  const response = await axiosNoAuthInstance.get(
    `${process.env.REACT_APP_API_URL}/chatbot/chatbot-happi?request_type=${requestType}`
  );

  const data = response?.data?.messages || [];

  return data as IChatInfor[];
}

export async function sendMsg(item: IChatBotBody) {
  const endpoint = `${process.env.REACT_APP_API_URL}/chatbot/chatbot-happi`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response?.data?.message || [];

  return data as IChatInfor[];
}

export async function readMsg(item: IReadMsgRequest) {
  const endpoint = `${process.env.REACT_APP_API_URL}/chatbot/chatbot-happi`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response.data;

  return data as { status: string };
}

export async function getChatbotSurvey(
  params?: IGetChatbotSurveyParams
): Promise<IChatData> {
  const response = await axiosNoAuthInstance.get(
    `${process.env.REACT_APP_API_URL}/chatbot/survey`,
    {
      params: {
        month: params?.month,
        status: params?.status?.toLowerCase(),
        name: params?.name,
      },
    }
  );

  const data = response.data;
  return data as IChatData;
}

export async function createChatbotSurvey(item: any) {
  const endpoint = `${process.env.REACT_APP_API_URL}/chatbot/survey`;

  const response = await axiosNoAuthInstance.post(endpoint, item, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = response.data;

  return data as { status: string };
}

export async function updateChatbotSurvey(item: any) {
  const endpoint = `${process.env.REACT_APP_API_URL}/chatbot/survey`;

  const response = await axiosNoAuthInstance.put(endpoint, item, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = response.data;

  return data as { status: string };
}
export async function deleteChatbotSurvey(id: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/chatbot/survey?id=${id}`;

  const response = await axiosNoAuthInstance.delete(endpoint);
  return response.data;
}

export async function sendFeedBack(item: IChatbotFeedback, domain?: string) {
  const endpoint = domain || `${process.env.REACT_APP_API_URL}/chatbot/chatbot-happi`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response.data;

  return data as { status: string };
}

export async function reactionMsg(item: IReactionMsg) {
  const endpoint = `${process.env.REACT_APP_API_URL}/chatbot/chatbot-happi`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response.data;

  return data as { status: string };
}
