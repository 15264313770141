import { createContext, useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Node,
  useNodesState,
  useEdgesState,
  Controls,
  ControlButton,
  Background,
  useStoreApi,
  ReactFlowProvider,
  getConnectedEdges,
  OnSelectionChangeParams,
  NodeChange,
  getIncomers,
  getOutgoers,
  ReactFlowInstance,
} from "reactflow";

import {
  MaximizeIcon,
  MinimizeIcon,
  InfoIcon,
  InfoPopup,
  Markers,
} from "./components";

import {
  edgeClassName,
  edgeMarkerName,
  calculateTargetPosition,
  calculateSourcePosition,
  initializeNodes,
  moveSVGInFront,
  setHighlightEdgeClassName,
  logTablePositions,
  setEdgeClassName,
  calculateEdges,
} from "./helpers";

import { EdgeConfig, DatabaseConfig } from "./types";

// this is important! You need to import the styles from the lib to make it work
import "reactflow/dist/style.css";
import "./Style";
import { nodeTypes } from "pages/config/nodeTypes";
import { DefaultLayout } from "layouts/DefaultLayout";
import { getModeling } from "services/modeling";
import { useAuthStore } from "stores/useAuthStore";
import { LoadingOutlined } from "@ant-design/icons";
import { WindowChat } from "components/WindowChat";
import { SelectTableModal } from "components/SelectTableModal";
import { useSelectTableModalStore } from "stores/useSelectTableModalStore";
import { useSelectedTableStore } from "stores/useSelectedTableStore";

interface FlowProps {
  currentDatabase: DatabaseConfig;
}

interface VisualizerProps {
  database?: string;
}

const Flow: React.FC<FlowProps> = (props: FlowProps) => {
  const currentDatabase = props.currentDatabase;
  const initialNodes = initializeNodes(props.currentDatabase);

  const store = useStoreApi();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [fullscreenOn, setFullScreen] = useState(false);
  const [infoPopupOn, setInfoPopupOn] = useState(false);
  const [nodeHoverActive, setNodeHoverActive] = useState(true);
  console.log("nodes1", nodes);

  const onInit = (instance: ReactFlowInstance) => {
    const nodes = instance.getNodes();
    const initialEdges = calculateEdges({ nodes, currentDatabase });
    console.log("nodes", nodes);
    console.log("initialEdges", initialEdges);
    setEdges(() => initialEdges);

    const handleKeyboard = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "p") {
        const nodes = instance.getNodes();

        logTablePositions(nodes);
      }
    };

    document.addEventListener("keydown", handleKeyboard);

    // https://javascriptf1.com/snippet/detect-fullscreen-mode-with-javascript
    window.addEventListener("resize", (event) => {
      setFullScreen(window.innerHeight === window.screen.height);
    });

    document.addEventListener("click", (event: Event) => {
      const popup = document.querySelector(".info-popup");

      if (!popup) {
        return;
      }

      const target = event.target as HTMLInputElement;

      if (target && target.closest(".into-popup-toggle")) {
        return;
      }
    });

    document.addEventListener(
      "keydown",
      (e: KeyboardEvent) => {
        if (e.code === "MetaLeft") {
          setNodeHoverActive(false);
        }
      },
      false
    );

    document.addEventListener(
      "keyup",
      (e: KeyboardEvent) => {
        if (e.code === "MetaLeft") {
          setNodeHoverActive(true);
        }
      },
      false
    );
  };

  // https://github.com/wbkd/react-flow/issues/2580
  const onNodeMouseEnter = useCallback(
    (_: any, node: Node) => {
      if (!nodeHoverActive) {
        return;
      }

      const state = store.getState();
      state.resetSelectedElements();
      state.addSelectedNodes([node.id]);

      const connectedEdges = getConnectedEdges([node], edges);
      setEdges((eds) => {
        return eds.map((ed) => {
          if (connectedEdges.find((e) => e.id === ed.id)) {
            setHighlightEdgeClassName(ed);
          }

          return ed;
        });
      });
    },
    [edges, nodeHoverActive, setEdges, store]
  );

  const onNodeMouseLeave = useCallback(
    (_: any, node: Node) => {
      if (!nodeHoverActive) {
        return;
      }

      const state = store.getState();
      state.resetSelectedElements();

      setEdges((eds) => eds.map((ed) => setEdgeClassName(ed)));

      // https://stackoverflow.com/questions/2520650/how-do-you-clear-the-focus-in-javascript
      (document.activeElement as HTMLElement).blur();
    },
    [nodeHoverActive, setEdges, store]
  );

  const onSelectionChange = useCallback((params: OnSelectionChangeParams) => {
    const edges = params.edges;
    edges.forEach((ed) => {
      const svg = document
        .querySelector(".react-flow__edges")
        ?.querySelector(`[data-testid="rf__edge-${ed.id}"]`);
      moveSVGInFront(svg);
    });
  }, []);

  const handleNodesChange = useCallback(
    (nodeChanges: NodeChange[]) => {
      nodeChanges.forEach((nodeChange) => {
        if (nodeChange.type === "position" && nodeChange.positionAbsolute) {
          // nodeChange.positionAbsolute contains new position
          const node = nodes.find((node) => node.id === nodeChange.id);

          if (!node) {
            return;
          }

          const incomingNodes = getIncomers(node, nodes, edges);
          incomingNodes.forEach((incomingNode) => {
            const edge = edges.find((edge) => {
              return edge.id === `${incomingNode.id}-${node.id}`;
            });

            const edgeConfig = currentDatabase.edgeConfigs.find(
              (edgeConfig: EdgeConfig) => {
                return (
                  edgeConfig.source === incomingNode.id &&
                  edgeConfig.target === node.id
                );
              }
            );

            if (nodeChange.positionAbsolute?.x) {
              setEdges((eds) =>
                eds.map((ed) => {
                  if (edge && ed.id === edge.id) {
                    const sourcePosition =
                      edgeConfig!.sourcePosition ||
                      calculateSourcePosition(
                        incomingNode.width as number,
                        incomingNode.position.x,
                        node.width as number,
                        nodeChange.positionAbsolute!.x
                      );
                    const targetPosition =
                      edgeConfig!.targetPosition ||
                      calculateTargetPosition(
                        incomingNode.width as number,
                        incomingNode.position.x,
                        node.width as number,
                        nodeChange.positionAbsolute!.x
                      );

                    const sourceHandle = `${
                      edgeConfig!.sourceKey
                    }-${sourcePosition}`;
                    const targetHandle = `${
                      edgeConfig!.targetKey
                    }-${targetPosition}`;

                    ed.sourceHandle = sourceHandle;
                    ed.targetHandle = targetHandle;
                    ed.className = edgeClassName(edgeConfig, targetPosition);
                    ed.markerEnd = edgeMarkerName(edgeConfig, targetPosition);
                  }

                  return ed;
                })
              );
            }
          });

          const outgoingNodes = getOutgoers(node, nodes, edges);
          outgoingNodes.forEach((targetNode) => {
            const edge = edges.find((edge) => {
              return edge.id === `${node.id}-${targetNode.id}`;
            });

            const edgeConfig = currentDatabase.edgeConfigs.find(
              (edgeConfig: EdgeConfig) => {
                return (
                  edgeConfig.source === nodeChange.id &&
                  edgeConfig.target === targetNode.id
                );
              }
            );

            if (nodeChange.positionAbsolute?.x) {
              setEdges((eds) =>
                eds.map((ed) => {
                  if (edge && ed.id === edge.id) {
                    const sourcePosition =
                      edgeConfig!.sourcePosition ||
                      calculateSourcePosition(
                        node.width as number,
                        nodeChange.positionAbsolute!.x,
                        targetNode.width as number,
                        targetNode.position.x
                      );
                    const targetPosition =
                      edgeConfig!.targetPosition ||
                      calculateTargetPosition(
                        node.width as number,
                        nodeChange.positionAbsolute!.x,
                        targetNode.width as number,
                        targetNode.position.x
                      );

                    const sourceHandle = `${
                      edgeConfig!.sourceKey
                    }-${sourcePosition}`;
                    const targetHandle = `${
                      edgeConfig!.targetKey
                    }-${targetPosition}`;

                    ed.sourceHandle = sourceHandle;
                    ed.targetHandle = targetHandle;
                    ed.className = edgeClassName(edgeConfig, targetPosition);
                    ed.markerEnd = edgeMarkerName(edgeConfig, targetPosition);
                  }

                  return ed;
                })
              );
            }
          });
        }
      });

      onNodesChange(nodeChanges);
    },
    [onNodesChange, setEdges, nodes, edges, currentDatabase]
  );

  const toggleFullScreen = () => {
    if (fullscreenOn) {
      document
        .exitFullscreen()
        .then(function () {
          setFullScreen(false);
        })
        .catch(function (error) {
          alert("Can't exit fullscreen");
          console.error(error);
        });
    } else {
      var element = document.querySelector("body");

      // make the element go to full-screen mode
      element &&
        element
          .requestFullscreen()
          .then(function () {
            setFullScreen(true);
          })
          .catch(function (error) {
            alert("Can't turn on fullscreen");
            console.error(error);
          });
    }
  };

  // https://stackoverflow.com/questions/16664584/changing-an-svg-markers-color-css
  return (
    <div className="Flow">
      <Markers />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={onEdgesChange}
        onInit={onInit}
        // snapToGrid={true}
        fitView
        // snapGrid={[16, 16]}
        nodeTypes={nodeTypes}
        onNodeMouseEnter={onNodeMouseEnter}
        onNodeMouseLeave={onNodeMouseLeave}
        onSelectionChange={onSelectionChange}
      >
        <Controls showInteractive={false}>
          {/* <ControlButton onClick={toggleFullScreen}>
            {!fullscreenOn && <MaximizeIcon />}
            {fullscreenOn && <MinimizeIcon />}
          </ControlButton> */}
        </Controls>
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
};

interface createContextType {
  isShowModel: boolean;
  dataConfig: DatabaseConfig;
  updateDBConfig: (dataConfig: DatabaseConfig) => void;
  updateIsShowModel: (isShowModel: boolean) => void;
}

export const VisualizerContext = createContext<createContextType>({
  isShowModel: false,
  dataConfig: {
    tables: [],
    edgeConfigs: [],
    schemaColors: {},
    tablePositions: {},
  },
  updateDBConfig: () => {},
  updateIsShowModel: () => {},
});
// https://codesandbox.io/s/elastic-elion-dbqwty?file=/src/App.js
// eslint-disable-next-line import/no-anonymous-default-export
const Visualizer: React.FC<VisualizerProps> = (props: VisualizerProps) => {
  const [currentDatabase, setCurrentDatabase] = useState({
    tables: [],
    edgeConfigs: [],
    schemaColors: {},
    tablePositions: {},
  } as DatabaseConfig);
  const [isShowModel, setIsShowModel] = useState(true);
  const [dataConfig, setDBConfig] = useState({
    tables: [],
    edgeConfigs: [],
    schemaColors: {},
    tablePositions: {},
  } as DatabaseConfig);
  const { userId } = useAuthStore();
  const { isOpen } = useSelectTableModalStore();
  const {setListSelectedTable} = useSelectedTableStore()

  const getDataModeling = async () => {
    if (!userId) return;
    try {
      const data = await getModeling(userId);
      console.log("data", data);

      const databaseConfig = data as DatabaseConfig;
      // const databaseConfig: DatabaseConfig = {
      //   tables: [
      //     {
      //       name: "retail_dealer_dim",
      //       description:
      //         "This table contains information about retail dealers, including their unique IDs and addresses.",
      //       schemaColor: "#91C4F2",
      //       columns: [
      //         {
      //           name: "Retail Dealer ID",
      //           key: true,
      //           description: "A unique identifier for each retail dealer.",
      //           type: "integer",
      //           handleType: "source",
      //         },
      //         {
      //           name: "Dealer Address",
      //           key: false,
      //           description:
      //             "The address of the retail dealer, including city and country.",
      //           type: "character varying",
      //         },
      //       ],
      //     },
      //     {
      //       name: "sales_territory",
      //       description:
      //         "This table contains information about different sales territories and their corresponding countries.",
      //       schemaColor: "#91C4F2",
      //       columns: [
      //         {
      //           name: "Sales Territory",
      //           key: true,
      //           description: "The name or identifier of the sales territory.",
      //           type: "character varying",
      //           handleType: "source",
      //         },
      //         {
      //           name: "country",
      //           key: false,
      //           description: "The country associated with the sales territory.",
      //           type: "character varying",
      //         },
      //       ],
      //     },
      //     {
      //       name: "raw_tbl",
      //       description:
      //         "This table contains records of car sales by various retail dealers. It includes details about the dealer, the number of cars sold, the sale date, the sale territory, and the type of car sold.",
      //       schemaColor: "#91C4F2",
      //       columns: [
      //         {
      //           name: "Retail Dealer Code",
      //           key: true,
      //           description: "A unique code assigned to each retail dealer.",
      //           type: "integer",
      //           handleType: "source",
      //         },
      //         {
      //           name: "Retail Dealer Name",
      //           key: false,
      //           description: "The name of the retail dealer.",
      //           type: "character varying",
      //         },
      //         {
      //           name: "Cars Sold",
      //           key: false,
      //           description:
      //             "The number of cars sold by the dealer, represented as a floating-point number.",
      //           type: "numeric",
      //         },
      //         {
      //           name: "Sale Date",
      //           key: false,
      //           description:
      //             "The date when the sale was made. The format of the date varies and includes different date formats.",
      //           type: "date",
      //         },
      //         {
      //           name: "Sale Territory",
      //           key: false,
      //           description:
      //             "The territory where the sale was made. There are some inconsistencies in the territory names.",
      //           type: "character varying",
      //         },
      //         {
      //           name: "Car Type",
      //           key: false,
      //           description:
      //             "The type of car sold, represented by a single character.",
      //           type: "character varying",
      //         },
      //       ],
      //     },
      //     {
      //       name: "cars",
      //       description:
      //         "This table contains information about different car types, their brands, sales territories, and the year they were manufactured.",
      //       schemaColor: "#91C4F2",
      //       columns: [
      //         {
      //           name: "Car Type",
      //           key: true,
      //           description: "The type or model category of the car.",
      //           type: "character varying",
      //           handleType: "source",
      //         },
      //         {
      //           name: "Brand",
      //           key: false,
      //           description: "The brand or manufacturer of the car.",
      //           type: "character varying",
      //         },
      //         {
      //           name: "Sales Territory",
      //           key: false,
      //           description: "The geographical area where the car is sold.",
      //           type: "character varying",
      //         },
      //         {
      //           name: "Year of Mfg",
      //           key: false,
      //           description: "The year the car was manufactured.",
      //           type: "date",
      //         },
      //       ],
      //     },
      //   ],
      //   tablePositions: {
      //     retail_dealer_dim: {
      //       x: -312.1320343559644,
      //       y: -207.48320791469882,
      //     },
      //     sales_territory: { x: 500, y: 100 },
      //     raw_tbl: { x: 100, y: 100 },
      //     cars: { x: 112.13203435596435, y: 831.7472766266275 },
      //   },
      //   edgeConfigs: [
      //     {
      //       source: "public.raw_tbl",
      //       sourceKey: "Retail Dealer Code",
      //       target: "public.retail_dealer_dim",
      //       targetKey: "Retail Dealer ID",
      //       relation: "hasMany",
      //     },
      //     {
      //       source: "public.raw_tbl",
      //       sourceKey: "Sale Territory",
      //       target: "public.sales_territory",
      //       targetKey: "Sales Territory",
      //       relation: "hasMany",
      //     },
      //     {
      //       source: "public.raw_tbl",
      //       sourceKey: "Car Type",
      //       target: "public.cars",
      //       targetKey: "Car Type",
      //       relation: "hasMany",
      //     },
      //   ],
      //   schemaColors: {
      //     DEFAULT: "#91C4F2",
      //     public: "#91C4F2",
      //   },
      // };
      setCurrentDatabase(databaseConfig);
      setIsShowModel(true);
    } catch (error) {
      setIsShowModel(true);
    }
  };

  useEffect(() => {
    setListSelectedTable([])
  }, [])

  useEffect(() => {
    console.log(dataConfig);
    if (dataConfig.tables.length > 0) {
      const data = dataConfig.edgeConfigs.map((item) => {
        const { source, sourceKey, target, targetKey, relation } = item;
        return {
          source: `public.${source.split('.').length > 0 ? source.split('.')[1] : source.split('.')[0]}`,
          sourceKey,
          target: `public.${target.split('.').length > 0 ? target.split('.')[1] : target.split('.')[0]}`,
          targetKey,
          relation
        };
      });
      dataConfig.edgeConfigs = data
      console.log(dataConfig);
      setIsShowModel(false);
      setTimeout(() => {
        setCurrentDatabase(dataConfig);
        setIsShowModel(true);
      }, 500);
    }
  }, [dataConfig]);

  useEffect(() => {
    // getDataModeling();
    // if (!userId) return
    // getModeling(userId).then((data) => {
    //   // if (!props.database || !(props.database in data)) {
    //   //   return;
    //   // }
    //   console.log('data', data)
    //   const databaseConfig = data[props.database as string] as DatabaseConfig;
    //   // const databaseConfig = {
    //   //   tables: [
    //   //     {
    //   //       name: "dim_product",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "product_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "product_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "category",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "price",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "numeric",
    //   //         },
    //   //         {
    //   //           name: "supplier",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //       ],
    //   //     },
    //   //     {
    //   //       name: "fact_sales",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "sale_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "product_id",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //         {
    //   //           name: "customer_id",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //         {
    //   //           name: "store_id",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //         {
    //   //           name: "date_id",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //         {
    //   //           name: "quantity",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //         {
    //   //           name: "total_amount",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "numeric",
    //   //         },
    //   //       ],
    //   //     },
    //   //     {
    //   //       name: "dim_customer",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "customer_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "first_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "last_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "email",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "phone",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "address",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //       ],
    //   //     },
    //   //     {
    //   //       name: "dim_store",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "store_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "store_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "location",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "manager",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "opening_date",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "date",
    //   //         },
    //   //       ],
    //   //     },
    //   //     {
    //   //       name: "dim_date",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "date_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "calendar_date",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "date",
    //   //         },
    //   //         {
    //   //           name: "day_of_week",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "month",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //         {
    //   //           name: "quarter",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //         {
    //   //           name: "year",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //       ],
    //   //     },
    //   //     {
    //   //       name: "dim_promotion",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "promotion_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "promotion_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "discount_percent",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "numeric",
    //   //         },
    //   //         {
    //   //           name: "start_date",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "date",
    //   //         },
    //   //         {
    //   //           name: "end_date",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "date",
    //   //         },
    //   //       ],
    //   //     },
    //   //     {
    //   //       name: "dim_salesperson",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "salesperson_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "first_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "last_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "email",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "phone",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "hire_date",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "date",
    //   //         },
    //   //       ],
    //   //     },
    //   //     {
    //   //       name: "dim_region",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "region_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "region_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "country",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "manager",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //       ],
    //   //     },
    //   //     {
    //   //       name: "dim_supplier",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "supplier_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "supplier_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "contact_name",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "contact_phone",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //         {
    //   //           name: "address",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "character varying",
    //   //         },
    //   //       ],
    //   //     },
    //   //     {
    //   //       name: "dim_time",
    //   //       description: "",
    //   //       schemaColor: "#91C4F2",
    //   //       columns: [
    //   //         {
    //   //           name: "time_id",
    //   //           key: true,
    //   //           description: "",
    //   //           type: "integer",
    //   //           handleType: "source",
    //   //         },
    //   //         {
    //   //           name: "hour",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //         {
    //   //           name: "minute",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //         {
    //   //           name: "second",
    //   //           key: false,
    //   //           description: "",
    //   //           type: "integer",
    //   //         },
    //   //       ],
    //   //     },
    //   //   ],
    //   //   tablePositions: {
    //   //     dim_product: {
    //   //       x: 500,
    //   //       y: 100,
    //   //     },
    //   //     fact_sales: {
    //   //       x: 100,
    //   //       y: 100,
    //   //     },
    //   //     dim_customer: {
    //   //       x: 99.99999999999993,
    //   //       y: -500,
    //   //     },
    //   //     dim_store: {
    //   //       x: -300,
    //   //       y: 100.00000000000007,
    //   //     },
    //   //     dim_date: {
    //   //       x: 100.00000000000003,
    //   //       y: 700,
    //   //     },
    //   //     dim_promotion: {
    //   //       x: 2100,
    //   //       y: 100,
    //   //     },
    //   //     dim_salesperson: {
    //   //       x: 400,
    //   //       y: 700,
    //   //     },
    //   //     dim_region: {
    //   //       x: 800,
    //   //       y: 700,
    //   //     },
    //   //     dim_supplier: {
    //   //       x: 1200,
    //   //       y: 700,
    //   //     },
    //   //     dim_time: {
    //   //       x: 1600,
    //   //       y: 700,
    //   //     },
    //   //   },
    //   //   edgeConfigs: [
    //   //     {
    //   //       source: "public.dim_product",
    //   //       sourceKey: "product_id",
    //   //       target: "public.fact_sales",
    //   //       targetKey: "product_id",
    //   //       relation: "hasMany",
    //   //     },
    //   //     {
    //   //       source: "public.dim_customer",
    //   //       sourceKey: "customer_id",
    //   //       target: "public.fact_sales",
    //   //       targetKey: "customer_id",
    //   //       relation: "hasMany",
    //   //     },
    //   //     {
    //   //       source: "public.dim_store",
    //   //       sourceKey: "store_id",
    //   //       target: "public.fact_sales",
    //   //       targetKey: "store_id",
    //   //       relation: "hasMany",
    //   //     },
    //   //     {
    //   //       source: "public.dim_date",
    //   //       sourceKey: "date_id",
    //   //       target: "public.fact_sales",
    //   //       targetKey: "date_id",
    //   //       relation: "hasMany",
    //   //     },
    //   //   ],
    //   //   schemaColors: {
    //   //     DEFAULT: "#91C4F2",
    //   //     public: "#91C4F2",
    //   //   },
    //   // };
    //   console.log(databaseConfig);
    //   setCurrentDatabase(databaseConfig);
    //   setDatabasesLoaded(true);
    // });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <VisualizerContext.Provider
      value={{
        isShowModel,
        dataConfig,
        updateDBConfig: (dataConfig: DatabaseConfig) => setDBConfig(dataConfig),
        updateIsShowModel: (isShowModel: boolean) =>
          setIsShowModel(isShowModel),
      }}
    >
      <DefaultLayout>
        <div style={{ height: "calc(100vh - 114px)" }}>
          <ReactFlowProvider>
            {isShowModel && <Flow currentDatabase={currentDatabase} />}
            {!isShowModel && (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingOutlined style={{ fontSize: "30px" }} />
              </div>
            )}
          </ReactFlowProvider>

          <WindowChat />
          {isOpen && <SelectTableModal />}
        </div>
      </DefaultLayout>
    </VisualizerContext.Provider>
  );
};

export default Visualizer;
