// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", sans-serif;
}

.ant-form-item {
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #d9d9d9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,SAAA;EACA,gCAAA;EACA,mCAAA;EACA,kCAAA;AAAF;;AAGA;EACE,gCAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AADF;;AAIA,UAAA;AACA;EACE,mBAAA;AADF;;AAIA,WAAA;AACA;EACE,kBAAA;EACA,mBAAA;AADF;;AAIA,oBAAA;AACA;EACE,gBAAA;AADF","sourcesContent":["@import \"~antd/dist/reset.css\";\r\n\r\ntable {\r\n  border-collapse: collapse;\r\n  border-spacing: 0;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: \"Inter\", sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\ncode {\r\n  font-family: \"Inter\", sans-serif;\r\n}\r\n\r\n.ant-form-item {\r\n  margin-bottom: 0;\r\n}\r\n\r\n\r\n::-webkit-scrollbar {\r\n  width: 10px;\r\n  height: 10px;\r\n  border-radius: 5px;\r\n}\r\n\r\n/* Track */\r\n::-webkit-scrollbar-track {\r\n  background: #f1f1f1;\r\n}\r\n \r\n/* Handle */\r\n::-webkit-scrollbar-thumb {\r\n  border-radius: 5px;\r\n  background: #d9d9d9; \r\n}\r\n\r\n/* Handle on hover */\r\n::-webkit-scrollbar-thumb:hover {\r\n  background: #555; \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
