import { IChat2ChartInfor } from "types/chat2Chart";
import { IConnectionData, IConnectionModel, IDataQualityParam, ITableIndexerParam } from "types/dataQuality";
import { axiosNoAuthInstance } from "utils/axios";

const url = process.env.REACT_APP_API_IVY_ANALYTICS

export async function createConnection(item: IConnectionModel, username: string) {
  const endpoint = `${url}/api/v1/data-connector/add-connection?username=${username}`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response?.data?.message || [];

  return data;
}

export async function getListConnections(username: string) {
  const endpoint = `${url}/api/v1/data-connector/list-connections?username=${username}`;

  const response = await axiosNoAuthInstance.post(endpoint);
  const data = response?.data || [];

  return data as IConnectionData[];
}

export async function getListTables(username: string, connectionId: string) {
  const endpoint = `${url}/api/v1/database-operations/list-tables?username=${username}&connection_id=${connectionId}`;

  const response = await axiosNoAuthInstance.post(endpoint);
  const data = response?.data || [];

  return data as string[];
}

export async function indexTable(item: ITableIndexerParam) {
  const endpoint = `${url}/api/v1/data-modeling/table-indexer`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response?.data || [];

  return data;
}

export async function sendDataModelingMsg(item: IDataQualityParam) {
  const endpoint = `${url}/api/v1/data-modeling/agent/chat`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response?.data;

  return data as IChat2ChartInfor;
}

export async function sendMsg(item: IDataQualityParam) {
  const endpoint = `${url}/api/v1/data-quality/agent/chat`;

  const response = await axiosNoAuthInstance.post(endpoint, item);
  const data = response?.data;

  return data as IChat2ChartInfor;
}