import styled from "styled-components";

export const ChatBotWrap = styled.div`
  border-radius: 16px 16px 0px 16px;
  background: #f5f5f5;
  width: 460px;
  overflow: hidden;
  box-shadow: 0px 3px 36px 0px #0000001a;
  box-shadow: 0px 0px 4px 0px #00000029;
`;

export const ChatBotHeader = styled.div`
  padding: 8px 20px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ChatBotBody = styled.div`
  max-height: 518px;
  height: 60vh;
  padding: 20px;
  overflow: auto;
`;

export const ChatBotMessageReceive = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: flex-end;
  .content {
    margin-left: 8px;
    background: #f0f0f0;
    color: #262626;
    padding: 20px 24px;
    line-height: 22px;
    border-radius: 0 12px 12px 0;
    max-width: 70%;
  }
`;

export const ChatBotMessageSend = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  .content {
    background: linear-gradient(270deg, #c4efe4 0%, #cdf7f0 100%);
    color: #141414;
    padding: 20px 24px;
    line-height: 22px;
    border-radius: 12px 0 0 12px;
    max-width: 70%;
  }
`;

export const ChatBotNewMsg = styled.div`
  background: red;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  right: -5px;
  font-weight: bold;
`;

export const ChatBotFooter = styled.div`
  height: 66px;
  border-top: 1px solid #f0f0f0;
  background-color: #fff;
  padding: 11px 20px;
  position: relative;
  display: flex;
  align-items: center;
  .message-options {
    left: 0px;
    position: absolute;
    bottom: 66px;
    width: 100%;
    background: #fafafa;
    padding: 8px 20px 2px;
    .message {
      background: #f9f0ff;
      border: 1px solid #ffffff;
      padding: 8px 12px;
      border-radius: 50px;
      color: #213992;
      width: auto;
      display: inline-block;
      cursor: pointer;
      margin-right: 6px;
      margin-bottom: 6px;
      box-shadow: 0px 1px 6px 0px #2506581a;
      &.active {
        background: #efdbff;
      }
    }
  }
  input {
    width: 100%;
    border: none;
    &:active,
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
  button {
    box-shadow: none !important;
    border: none !important;
    background-color: transparent;
  }
`;

export const ChatBotNoti = styled.div`
  position: relative;
  margin-bottom: 5px;
  font-weight: 500;
  text-align: center;
  max-width: 200px;
  padding: 12px 16px 12px 16px;
  border-radius: 18px;
  opacity: 0px;
  background: radial-gradient(
    133.61% 325.12% at 0% -10.71%,
    rgba(222, 211, 255, 0.9) 3.94%,
    rgba(255, 255, 255, 0.9) 34.73%,
    rgba(255, 255, 255, 0.9) 67.04%
  );
  box-shadow: 1px 5px 16px 0px #722ed14d;
  &::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    border-right: 6px solid white;
    border-top: 6px solid white;
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
    top: 100%;
    right: 50px;
  }
`;
