import { create } from 'zustand';

interface ISelectedTableState {
  connectionName: string;
  listSelectedTable: string[];
  setConnectionName: (connectionName: string) => void;
  setListSelectedTable: (listSelectedTable: string[]) => void;
}

export const useSelectedTableStore = create<ISelectedTableState>()(
  (set) => ({
    connectionName: '',
    listSelectedTable: [],
    setConnectionName: (connectionName) => set({ connectionName }),
    setListSelectedTable:  (listSelectedTable) => set({ listSelectedTable }),
  })
);
